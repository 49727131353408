/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

 import React, { ReactNode } from "react"
 import Helmet from "react-helmet"
 import { useStaticQuery, graphql } from "gatsby"
 
 interface Props {
   description?: string
   title?: string
   lang?: string
   meta?: Meta
   ogImage?: string
   children?: ReactNode
 }
 
 type Meta = ConcatArray<PropertyMetaObj | NameMetaObj>
 
 type PropertyMetaObj = {
   property: string
   content: string
 }
 
 type NameMetaObj = {
   name: string
   content: string
 }
 const Seo: React.FC<Props> = ({ description, lang = 'pl', meta = [], title, ogImage, children }) => {
   const { site, fileName } = useStaticQuery(
     graphql`{
   site {
     siteMetadata {
       title
       description
       author
     }
   }
   fileName: file(relativePath: {eq: "meta/image.jpeg"}) {
     childImageSharp {
       gatsbyImageData(width: 1024, placeholder: BLURRED, layout: FIXED)
     }
   }
 }
 `
   )
 
   const metaDescription = description || site.siteMetadata.description;
   const siteTitle = title || site.siteMetadata.title;
   const siteOgImage = ogImage || fileName.childImageSharp.gatsbyImageData.images.fallback.src;
 
   return (
     <Helmet
       htmlAttributes={{
         lang,
       }}
       title={siteTitle}
       meta={[
         {
           name: `description`,
           content: metaDescription,
         },
         {
           property: `og:image`,
           content: siteOgImage,
         },
         {
           property: `og:title`,
           content: siteTitle,
         },
         {
           property: `og:description`,
           content: metaDescription,
         },
         {
           property: `og:type`,
           content: `website`,
         },
         {
           name: `twitter:card`,
           content: `summary`,
         },
         {
           name: `twitter:creator`,
           content: site.siteMetadata.author,
         },
         {
           name: `twitter:title`,
           content: siteTitle,
         },
         {
           name: `twitter:description`,
           content: metaDescription,
         },
       ].concat(meta)}
     >
       <script>
         {
           `!function (w, d, t) {
             w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
             ttq.load('C4J105417T561FR1K030');
             ttq.page();
           }(window, document, 'ttq');`
         }
       </script>       
       {children}
     </Helmet>
   )
 }
 
 export default Seo
